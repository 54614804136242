import React from "react";
import Layout from "../components/shared/Layout";

const Error404Page = () => {
  return (
    <Layout>
      <section className="relative flex w-screen h-screen">
        <div className="m-auto">
          <h1 className="text-5xl xl:text-8xl">404</h1>
          <h2>Page Not Found</h2>
        </div>
      </section>
    </Layout>
  );
};

export default Error404Page;
